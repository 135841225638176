import Shell from "@shared/Shell";
import useTransactionsForDay from "@hooks/useTransactionsForDay";
import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";
import Table from "@shared/Table";
import dateFormat from "dateformat";
import { formatPrice, parsePrice, isToday, stripTimeFromDateString } from "@shared/Utils";
import { FLOORS } from "@constants/floors";
import FloorContext from "@contexts/FloorContext";
import {CloseButton} from "@shared/Buttons";
import { Stallholder} from '@sharedtypes/Stallholder';
import { APIServiceContextValue } from '@shared/ContextProviders/APIServiceContext';
import useStallholders from "@hooks/useStallholders";
import log from "loglevel";
import { useState, useEffect, useContext, useRef, Fragment, useMemo } from 'react'
import useTillAPIService from "@hooks/useTillAPIService";
import {  Transition, Dialog } from '@headlessui/react'
import TransactionEdit from "@components/TransactionEdit";
import {ZERO} from "@hooks/useTill";
import useStallholder from "@hooks/useStallholder";
import {  CheckCircleIcon, 
          CreditCardIcon, 
          BanknotesIcon, 
          ChevronLeftIcon, 
          ChevronRightIcon, 
          ArrowRightIcon, 
          } from '@heroicons/react/20/solid'
import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'
import {CASH, DEBIT, CREDIT, CONTRA, CONTRA_CASH} from '@constants/payment_types'
import {GOOD} from '@constants/status_types'
import {SpinnerWrapper} from "@shared/Spinner";
import { Switch } from '@headlessui/react'
import {DESCRIPTION_MIN_LENGTH} from '@constants/defaults'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import {PrinterIcon } from '@heroicons/react/24/outline'

import classnames from 'classnames';

const BG_SELECTED = "bg-sky-100 ";
const BG_UNSELECTED = "bg-white ";

function formatBackground(isSelected: boolean, css: string) {
  if (isSelected)
    return BG_SELECTED + css ;
  return BG_UNSELECTED + css;
}

function createColumns( selectedRow : number ) {

  return [
    {
      "id": "stallholder",
      "label": "Dealer",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "",
      "format": (cell: any, row: {}, index: number) => { 
 
        const transaction = row as CompletedTransaction;
        const date = new Date(transaction.timestamp);

        if (!isToday(date))
          return (<>{cell.dealer_id}</>);
        return (
          <div className="grid grid-cols-1 w-32"> 
            <div className="">
              {cell.dealer_id}
            </div>
            <div className="text-sm">
              {cell.name }
            </div>
          </div>
        );
      }
    },
    {
      "id": "price",
      "label": "Price",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2 py-1 pr-4",
      "format": (cell: any, row: {}, index: number) => { 

        const price = formatPrice(cell);

        const transaction = row as CompletedTransaction;
        if (transaction.status === GOOD)
          return price;
        return <div className="line-through">{price}</div>
      }
    },
    {
      "id": "net",
      "label": "Net",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2 py-1 pr-4",
      "format": (cell: any, row: {}, index: number) => { 
        const transaction = row as CompletedTransaction;
        if (transaction.price === transaction.net) return <div className="">-</div>
        return formatPrice(cell);
      }
    },
    {
      "id": "description",
      "label": "Description",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "py-1")},
      "headerStyle": "py-1",
      "format": (cell: any, row: {}, index: number) => { 
        return (
          <p className="w-48"> 
            {cell}
          </p>
        );
      }
    },
    {
      "id": "stockid",
      "label": "Stock ID",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2 py-1",
      "format": (cell: any, row: {}) => { 
        return (
          <p className="w-48"> 
            {cell}
          </p>
        );
      }
    },
  
    {
      "id": "timestamp",
      "label": "Date",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2 py-1",
      "format": (cell: any, row: {}, index: number) => { 
        const date = new Date(cell);
        return dateFormat(date, "HH:MM");
        /*
        
        if (isToday(date)) return dateFormat(date, "h:MM");
        return <p className="w-32">{dateFormat(date, "d mmmm")}</p>;
        */
      }
    },
    {
      "id": "floor",
      "label": "Floor",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2  py-1",
      "format": (cell: any, row: {}, index: number) => { 
        return FLOORS[cell]
      }
    },
    {
      "id": "till",
      "label": "Till",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2  py-1"
    },
    {
      "id": "paymentType",
      "label": "Payment",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2  py-1",
      "format": (cell: any, row: {}, index: number) => { 
        
        if(cell === CASH)
          return "Cash";
        else if(cell === DEBIT)
          return "Debit";
        else if(cell === CREDIT)
          return "Credit"
        else if(cell === CONTRA)
          return "Contra"
        else if(cell === CONTRA_CASH)
          return "Cash Contra"
        return "Error: Unknown type"
      }
    },
    {
      "id": "isQPitch",
      "label": "Q",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "pl-2 py-1 text-lg")},
      "headerStyle": "pl-2  py-1",
      "format": (cell: any, row: {}, index: number) => { 
        if(cell)
          return <CheckCircleIcon className="h-6 w-6 text-amber-500"/>;
        else 
          return "";
      }
    },
    {
      "id": "isCNP",
      "label": "CNP",
      "cellStyle": (row: number) => {return formatBackground(row === selectedRow, "py-1 text-lg")},
      "headerStyle": "py-1",
      "format": (cell: any, row: {}, index: number) => { 
        if(cell)
          return <CheckCircleIcon className="h-6 w-6 text-rose-500"/>;
        else 
          return "";
      }
    },
  ]
}

export function Sales() {
  const {floor} = useContext(FloorContext);

  return (
    <>
    {
      floor > -1 ?
      <SalesImpl /> :
      <div></div>
    }
    </>
  );
}

function SalesImpl() {
  const {floor} = useContext(FloorContext);
  const [selectedRow, setSelectedRow] = useState(-1) ;

  /*
  const [columns] = useMemo(
    () => { return createColumns(processEdit, processDelete, processRefund)},
    [processEdit, processDelete, processRefund]
  );
*/

  const selected = useMemo(() => {
    return (index: number) => {
      log.debug("selected:", index);

      if (selectedRow !== index)
        setSelectedRow(index);
      else
        setSelectedRow(-1);
    };
  }, [selectedRow]);

  const [columns, setColumns] = useState(createColumns(selectedRow));
  const [date, setDate] = useState<Date>(new Date());
  const [search, setSearch] = useState<string>("");
  const apiService  = useTillAPIService();
  const [showDialog, setShowDialog] = useState(false);
  const [buttonsEnabled, setButtonsEnabled] = useState(false);

  const {isLoading, data, refetch} = useTransactionsForDay(date, floor);
  const [transactions, setTransactions] = useState<CompletedTransaction[]>([]);
  const stallholdersContext = useStallholders();
  const tableScroll = useRef<HTMLInputElement>(null);
  const [transaction, setTransaction] = useState<CompletedTransaction | undefined>(undefined);

  function setDayBefore() {

    setSelectedRow(-1);
    setSearch("");
    var newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() - 1);
    setDate(newDate);
  }

  function setDayAfter() {

    setSelectedRow(-1);
    setSearch("");
    var newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + 1);
    setDate(newDate);
  }

  /*
  useEffect(() => {
    console.info("FLLLOOOOOOR:", floor);
    refetch();
  
  return () => {
  };
}, [floor, refetch]); */

  useEffect(() => {
    
      setColumns(createColumns(selectedRow));

      if (selectedRow > -1 && transactions[selectedRow].status === GOOD ) {

        const lastPaymentDate = transactions[selectedRow].stallholder?.lastPaymentDateCalculation;
        var isFuturePayment = true;

        if (lastPaymentDate)
          isFuturePayment = lastPaymentDate < transactions[selectedRow].timestamp;
  
        log.debug("lastPaymentDate", lastPaymentDate);
        log.debug("timestamp", transactions[selectedRow].timestamp);

        log.debug("isFuturePayment", isFuturePayment);

        if (isFuturePayment)
          setButtonsEnabled(true);
        else
          setButtonsEnabled(false);
      }
      else {
        setButtonsEnabled(false);
      }
    
    return () => {
    };
  }, [selectedRow, transactions]); 


  useEffect(() => {
    
    log.info("transactionSet", isLoading, data);
    
    if (stallholdersContext.stallholders.length === 0) {
      setTransactions([]);
    }
    else if (!isLoading){
      const enrichedTransactions : Array<CompletedTransaction> = [];
        
      data.forEach((transaction: any) => {
        
        filterSales(enrichedTransactions, transaction);

      });

      setTransactions(enrichedTransactions);
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor, data, stallholdersContext, isLoading, search]); 

  function filterSales(transactions : Array<CompletedTransaction>, transaction: any) {

    const stallholder = stallholdersContext.findStallholderByID(transaction.stallholder);
    const add = (search.length > 0) ? searchTransaction(transaction, stallholder) : true;

    if (add) {
      transactions.push({
        id: transaction.id,
        stallholder: stallholder,
        price: transaction.price,
        paymentType: transaction.paymentType,
        till: transaction.till,
        timestamp: transaction.timestamp,
        floor: transaction.floor,
        stockid: transaction.stockid,
        description: transaction.description,
        status: transaction.status,
        isQPitch: transaction.isQPitch,
        isCNP: transaction.isCNP,
        net: transaction.net  
      });
    }
  }

  function processDelete2() {

    if (selectedRow > -1) {
      deleteTransaction(apiService, transactions[selectedRow], refetch);
    }
  }

  function processRefund2() {

    if (selectedRow > -1) {
      refundTransaction(apiService, transactions[selectedRow], refetch);
    }
  }

  function processEdit2() {

    if (selectedRow > -1) {
      setShowDialog(true);
      setTransaction(transactions[selectedRow]);
    }
  }
  
  function searchTransaction(transaction: CompletedTransaction, stallholder: Stallholder | undefined) {

    const lowerCaseSearchKey = search.toLocaleLowerCase();

    if (transaction.description.toLowerCase().includes(lowerCaseSearchKey))
      return true;
    if (stallholder?.name.toLowerCase().includes(lowerCaseSearchKey))
      return true;
    if (stallholder?.id.toString().includes(lowerCaseSearchKey))
      return true;
    if (transaction.stockid.toLowerCase().includes(lowerCaseSearchKey))
      return true;
    return false;
  }

  const scroll = () => {
    if (tableScroll.current !== null)
      tableScroll.current.scrollLeft += 100;
  };

  function closeEditDlg (){
    setShowDialog(false);
    setTransaction(undefined);
  }

  return (
    <>
      <Shell pageIndex={1}>
        <div className="h-full">
          {
            isLoading ?
            <SpinnerWrapper /> :
            <>
            <EditDialog isOpen={showDialog} close={closeEditDlg} transaction={transaction} apiService={apiService} refetch={refetch}/>
            <SearchBar dayForward={setDayAfter} dayBack={setDayBefore} date={date} search={setSearch} scroll={scroll}/>
            <div className="flex justify-end">
              <ReactToPrint content={() => tableScroll.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button 
                      onClick={handlePrint}
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PrinterIcon className="w-6 h-6"/>
                        Print transactions & summary
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </div>
            <div ref={tableScroll} className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-scroll m-2">
              <Table data={transactions} columns={columns} rowSelected={selected} />
            </div>
            <div className="flex justify-start ">
              <button 
                  disabled={!buttonsEnabled}
                  onClick={processEdit2}
                  className="mt-10 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                  Edit
                </button>
                <button 
                  disabled={!buttonsEnabled}
                  onClick={processRefund2}
                  className="mt-10 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                  Refund
                </button>
                <button 
                  disabled={!buttonsEnabled}
                  onClick={processDelete2}
                  className="mt-10 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                  Delete
                </button>
            </div>
            </>
          }
        </div>
      </Shell>    
    </>
  );
}

function deleteTransaction(apiService:  APIServiceContextValue, transaction: CompletedTransaction, refetch: Function) {

  const created = stripTimeFromDateString(transaction.timestamp);
  log.debug(transaction.id, created);

  apiService.fetch("deleteTransaction", {id: transaction.id, created: created})
    .then((data) => {
      log.info("Deleted transaction" + JSON.stringify(data));
      refetch();
    })
    .catch(function (error) {
      log.info("Error deleting transaction:" + error);
    }
  )
}

function refundTransaction(apiService:  APIServiceContextValue, transaction: CompletedTransaction, refetch: Function) {

  const created = stripTimeFromDateString(transaction.timestamp);
  log.debug(transaction.id, created);

  apiService.fetch("refundTransaction", {id: transaction.id, created: created})
    .then((data) => {
      log.info("Refunded transaction" + JSON.stringify(data));
      refetch();
    })
    .catch(function (error) {
      log.info("Error refunding transaction:" + error);
    }
  )
}

type SearchBarProps = {
  date: Date;
  dayBack: ()=> any;
  dayForward: ()=> any;
  search: (text: string)=> any;
  scroll: ()=> any;
};

const SearchBar = ({ date, dayBack, dayForward, search, scroll }: SearchBarProps) => {

  const [buttonText, setButtonText] = useState<string>("Today");
  const [future, setFuture] = useState<boolean>(false);

  useEffect(() => {
    
    if (isToday(date)) {
      setButtonText("Today");
      setFuture(true);
    }
    else {
      setButtonText(dateFormat(date, "d mmmm"));
      setFuture(false);
    }
    return () => {
    };
  }, [date]);  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    search(value);
  }

  return (
  <>
    <div className="grid grid-flow-col justify-stretch items-center">
      <div className="flex items-center" >
        <button className="h-8 w-8" onClick={dayBack}>
          <ChevronLeftIcon />    
        </button>
        <button className="rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {buttonText}
        </button>
        <button 
          className="h-8 w-8" 
          onClick={dayForward} 
          disabled={future}
        >
          <ChevronRightIcon />    
        </button>
        <label htmlFor="search" className="sr-only">
            Search
        </label>
        <input
          type="search"
          name="search"
          id="search"
          className="ml-16 w-96 rounded-md border-0 py-1.5 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
          placeholder="Search by dealer, description or stock ID"
          onChange={handleSearchChange} 
        />
      </div>
      <div className="grid grid-flow-col justify-end items-center">
        <button className="h-6 w-6"  onClick={scroll}>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  </>);
}

type EditDialogProps = {
  isOpen: boolean;
  close: () => void;
  transaction: CompletedTransaction | undefined;
  apiService:  APIServiceContextValue;
  refetch: Function
};

const EditDialog = ({ isOpen, close, transaction, apiService, refetch}: EditDialogProps) => {
 
  const [price, setPrice] = useState<string>(ZERO);
  const [dealer, setDealer] = useState<Stallholder | undefined>(undefined);
  const [stock, setStock] = useState<string>("");
  const [isQPitch, setIsQPitch] = useState(false);
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState(0);
  const [isCNP, setIsCNP] = useState(false);
  const [showDealer, setShowDealer] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  //const [payment, setPayment] = useState(plans[0]);
  
  const stallholderContext = useStallholder();

  useEffect(() => {
    
    //log.info("transactionSet", isLoading, data);
    
    if (transaction !== undefined) {
      setDealer(transaction.stallholder);
      setPrice(formatPrice(transaction.price));
      setStock(transaction.stockid);
      setDescription(transaction.description);
      setPaymentType(transaction.paymentType);
      setIsCNP(transaction.isCNP);
      setIsQPitch(transaction.isQPitch);
    }
    return () => {
    };
  }, [transaction]); 

  function createPaymentFormat(cardPaymentType: number) {

    var clazz = "flex ml-5 mt-2 font-extrabold text-2xl rounded-full p-4 shadow-sm ";

    if (cardPaymentType === CASH && paymentType === CASH) 
      clazz = clazz + "bg-cyan-500 hover:bg-cyan-700 text-white ";
    else if (cardPaymentType === DEBIT && paymentType === DEBIT) 
      clazz = clazz + "bg-purple-500 hover:bg-purple-700 text-white" 
    else if (cardPaymentType === CREDIT && paymentType === CREDIT) 
      clazz = clazz + "bg-fuchsia-500 hover:bg-fuchsia-700 text-white";
    else if (cardPaymentType === CONTRA && paymentType === CONTRA) 
      clazz = clazz + "bg-lime-50 hover:bg-lime-700 text-lime-950 ";
    else if (cardPaymentType === CONTRA_CASH && paymentType === CONTRA_CASH) 
      clazz = clazz + "bg-lime-50 hover:bg-lime-700 text-lime-950 ";
    else 
      clazz = clazz + "bg-slate-400 hover:bg-slate-700 text-white";

    clazz = clazz + " focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    
   return clazz;
  }

  function editTransaction() {

    if (transaction !== undefined) {

      setProcessing(true);
      setShowOverlay(true);
      const created = stripTimeFromDateString(transaction.timestamp);
      log.debug(transaction.id, created);

      apiService.fetch("editTransaction", 
                        {
                          id: transaction.id, 
                          created: created,
                          stallholder: dealer?.dealer_id, 
                          stock: stock, 
                          description: description, 
                          price: parsePrice(price),
                          paymentType: paymentType,
                          isCNP: isCNP,
                          isQPitch: isQPitch
                        }
                      )
        .then((data) => {
          log.info("Edited transaction " + JSON.stringify(data));
          refetch();
          setProcessing(false);
        })
        .catch(function (error) {
          log.info("Error editing transaction:" + error);
        }
      )
    }
  }

  function complete() {
    setShowOverlay(false);
    setShowDealer(true);
    close();
  }

  const handleCNPChange = (state: boolean) => {
    setIsCNP(state);
  };

  return (
      
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className=" relative z-10" onClose={()=>{setShowDealer(true)}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="grid max-w-md transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  {
                    showOverlay ? 
                    <div className="col-start-1 row-start-1 grid content-end rounded-2xl bg-slate-700 opacity-[.80] w-full h-full z-50">
                      <div className="grid justify-items-center bg-black opacity-100 pb-6 mb-5">
                        <div className="mt-4">
                        {
                          processing 
                          ?
                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full border-2 border-red-600 bg-red-100">
                              <ClockIcon className="h-20 w-20 text-red-600"  />
                            </div> 
                          :

                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full border-2 border-green-600 bg-green-100">
                              <CheckIcon className="h-20 w-20 text-green-600"  />
                            </div> 
                        }
                        </div> 

                        <div className="mt-4">
                          <button
                            type="button"
                            className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={complete}
                            disabled={processing}
                          >
                            {
                              processing
                              ?
                                <>Processing</>
                              :
                                <>Close</>
                            }
                            
                          </button>
                        </div>
                      </div>
                    </div> :
                    <></>
                  }

                  <div className="col-start-1 row-start-1 p-6 pb-56">           
                    <div className="flex justify-end">
                      <CloseButton onClick={complete}/>
                    </div>
                    <div className="grid">
                      <TransactionEdit 
                              price={price} 
                              stock={stock} 
                              description={description} 
                              dealer={dealer} 
                              isQPitch={isQPitch}
                              setPrice={setPrice}
                              setDescription={setDescription}
                              setStock={setStock}
                              setDealer={setDealer}
                              setIsQPitch={setIsQPitch}
                              showDealer={showDealer}
                              setShowDealer={setShowDealer}
                              showFavs={false}>
                        <div className="mt-6">

                          <div className="flex items-center">
                            <button 
                              disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                              onClick={()=>{setPaymentType(DEBIT)}}
                              className={createPaymentFormat(DEBIT)}
                            >
                              <BanknotesIcon className="w-8 h-8 mr-5"/>
                              Debit
                            </button>
                            { paymentType === DEBIT  ?
                              <CheckCircleIcon className="mt-2 ml-4 h-16 w-16 text-emerald-500"/> : <div className="mt-2 ml-4 h-16 w-16"></div>
                            }
                          </div>

                          <div className="flex items-center">
                            <button 
                              disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                              onClick={()=>{setPaymentType(CREDIT)}}
                              className={createPaymentFormat(CREDIT)}
                            >
                              <CreditCardIcon className="w-8 h-8 mr-5"/>
                              Credit
                            </button>
                            { paymentType === CREDIT ?
                              <CheckCircleIcon className="mt-2 ml-4 h-16 w-16 text-emerald-500"/> : <div className="mt-2 ml-4 h-16 w-16"></div>
                            }
                          </div>

                          <div className="flex items-center">
                            <button 
                              disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                              onClick={()=>{setPaymentType(CASH)}}
                              className={createPaymentFormat(CASH)}
                            >
                              <BanknotesIcon className="w-8 h-8 mr-5"/>
                              Cash
                            </button>
                            { paymentType === CASH ?
                              <CheckCircleIcon className="mt-2 ml-4 h-16 w-16 text-emerald-500"/> : <div className="mt-2 ml-4 h-16 w-16"></div>
                            }
                          </div>

                          <div className="flex items-center">
                            <button 
                              disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                              onClick={()=>{setPaymentType(CONTRA)}}
                              className={createPaymentFormat(CONTRA)}
                            >
                              <BanknotesIcon className="w-8 h-8 mr-5"/>
                              Contra
                            </button>
                            { paymentType === CONTRA ?
                              <CheckCircleIcon className="mt-2 ml-4 h-16 w-16 text-emerald-500"/> : <div className="mt-2 ml-4 h-16 w-16"></div>
                            }
                          </div>

                          <div className="flex items-center">
                            <button 
                              disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                              onClick={()=>{setPaymentType(CONTRA_CASH)}}
                              className={createPaymentFormat(CONTRA_CASH)}
                            >
                              <BanknotesIcon className="w-8 h-8 mr-5"/>
                              Cash Contra
                            </button>
                            { paymentType === CONTRA_CASH ?
                              <CheckCircleIcon className="mt-2 ml-4 h-16 w-16 text-emerald-500"/> : <div className="mt-2 ml-4 h-16 w-16"></div>
                            }
                          </div>

                          <div className="flex items-center">
                            <Switch.Group as="div" className="mt-4 flex items-center justify-end">
                              <span className="mr-4">
                                <Switch.Label as="span" className="mt-4 font-medium leading-6 text-gray-900" passive>
                                Cardholder not present
                                </Switch.Label>
                              </span>
                              <Switch
                                checked={isCNP}
                                onChange={handleCNPChange}
                                className={classnames(
                                  isCNP ? 'bg-indigo-600' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classnames(
                                    isCNP ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                          </div>

                          <button 
                            disabled={description.length < DESCRIPTION_MIN_LENGTH || stallholderContext.stallholder === undefined}
                            onClick={editTransaction}
                            className="mt-6 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                            Update
                          </button>
                        </div>  
                      </TransactionEdit>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default Sales;
