import Shell from "@shared/Shell";
import useStallholder from "@hooks/useStallholder";
import FloorContext from "@contexts/FloorContext";
import {FLOORS} from '@constants/floors';
import { useEffect, useContext, useState, Fragment } from 'react'
import useTillAPIService from "@hooks/useTillAPIService";
import log from "loglevel";
import useCashMarks from "@hooks/useCashMarks";
import dateFormat from "dateformat";
import useLastReconciliation from "@hooks/useLastReconciliation";
import useTransactionsSince from "@hooks/useTransactionsSince";
import {RefreshButton} from "@shared/Buttons";
import { Transition, Dialog } from '@headlessui/react'
import {SpinnerWrapper} from "@shared/Spinner";
import TransactionsSummary from "@shared/TransactionsSummary";
import {Spinner} from "@shared/Spinner";

// investigate why so many remaps for cash
export function Reconciliation() {

  const {floor} = useContext(FloorContext);

  useEffect(() => {
    
    log.info("floor", floor);
    
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor]); 

  return (
    <>
      <Shell pageIndex={4}>
        <div>
          {
            (floor > -1) ?
            <ReconciliationImpl floor={floor} /> :
            <></>
          }
        </div>
      </Shell>    
    </>
  );
}

type ReconciliationProps = {
  floor: number;
};

const ReconciliationImpl = ({ floor }: ReconciliationProps) => {

  const stallholderContext = useStallholder();
  const apiTillService  = useTillAPIService();
  const {data, isLoading, refetch} = useCashMarks();
  const {data: last} = useLastReconciliation(floor);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    
    log.info("recs", last);
    
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last, isLoading]); 


  async function reconcile() {

    if (stallholderContext.stallholder !== undefined) {

      await apiTillService.fetch("markReconciliation", 
        {
          till: stallholderContext.stallholder.id,
          floor: floor
        }
        )
        .then((data) => {
          log.info("added mark " + JSON.stringify(data));
          refetch();
          })
        .catch(function (error) {
          log.info("Error editing transaction:" + error);
        }
      )
    }
  }

  function cancel() {
    setShowDialog(false);
  }

  async function confirm() {
    await reconcile();
    setShowDialog(false);
    window.location.reload();
  }

  return (
    <>

      <CompleteDialog isOpen={showDialog} cancel={cancel} confirm={confirm}/>

      <h1 className="text-xl font-bold text-left">Reconciliation</h1>

      {
        (data === undefined || floor === -1 || isLoading) ? <SpinnerWrapper />  :
        <>
          <p className="mt-10text-l font-bold text-left">
            Last reconciled {dateFormat(last, "dddd, mmmm dS, HH:MM")}
          </p>
          <button 
            className="mt-10 mb-12 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

            onClick={()=>setShowDialog(true)}
          >
            Mark reconciliation as complete
          </button>
          {
            (last !== undefined) ?
            <ReconciliationView floor={floor} date={last} /> :
            <></>
          }
        </>
      }

    </>
  );
}

type ReconciliationViewProps = {
  date: Date;
  floor: number;
};

const ReconciliationView = ({ date, floor}: ReconciliationViewProps) => {
  const {isLoading, data, refetch} = useTransactionsSince(date, floor);
  const [showSpinner, setShowSpinner] = useState(false);
  const [title] = useState(FLOORS[floor] + " floor transactions since " + dateFormat(date, "dddd, mmmm dS HH:MM"));

  useEffect(() => {

    log.debug("rec data:", data);
    if (!isLoading) {
      log.debug("rec data2:", data);
    }
  
    return () => {
    };
  }, [isLoading, data]);

  async function refresh() {
    setShowSpinner(true);
    await refetch();
    setShowSpinner(false);
  }

  return (
    <>
    {
      isLoading ?
      <div className="grid justify-items-center ">
        <div className="bg-white text-slate-500 h-32 w-32">
          <Spinner spin={true}/>
        </div> 
      </div> :
      <div>
        <div className="flex justify-end items-center mb-6">
          <RefreshButton onClick={refresh} showSpinner={showSpinner} />
        </div>
        <TransactionsSummary transactions={data} title={title}/>
      </div>
    }
    </>
  );
}


type CompleteDialogProps = {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
};

const CompleteDialog = ({ isOpen, cancel, confirm }: CompleteDialogProps) => {

  return (

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    Are you sure you want to mark as reconciled?
                  </div>        

                  <div className="mt-4">
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={confirm}
                    >
                      Yes, continue
                    </button>
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-100 disabled:border-red-900 border-1 border-emerald-900 p-4 text-emerald-500 shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={cancel}
                    >
                      No, cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default Reconciliation;
