import { useQuery } from '@tanstack/react-query'
import useTillAPIService from "@hooks/useTillAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";


type TransactionsQueryType = (
  date: Date,
  floor: number
) => any;

const useTransactionsForDay: TransactionsQueryType = (
  date: Date,
  floor: number
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useTillAPIService();
  const created = date.toISOString().split('T')[0];

  return useQuery( {
    queryKey: ['transactions:', floor, created], 
    queryFn: async () =>       
      apiService.fetch("getTransactionsForDay", {date: created, floor: floor})
      .then((data: any) => {
          return processTransactions(data);
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    //select: (response) => remap(response),
    staleTime: 10000,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useTransactionsForDay;
