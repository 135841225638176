import { useQuery } from '@tanstack/react-query'
import useTillAPIService from "@hooks/useTillAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";

type TransactionsQueryType = (
  date: Date,
  floor: number
) => any;

const useTransactionsSince: TransactionsQueryType = (
  date: Date,
  floor: number
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useTillAPIService();

  return useQuery( {
    queryKey: ['transactions:', floor], 
    queryFn: async () => 
      apiService.fetch("getTransactionsSince", {date: date, floor: floor})
      .then((data: any) => {
        return processTransactions(data);
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    //select: (response) => remap(response),
    staleTime: 10000,
    refetchInterval: 60000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })
}

export default useTransactionsSince;
